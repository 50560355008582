import React from "react"
import Prices from "../../components/Prices"
import "../../styles/prices.scss"
import dataPrice from "../../data/price.json"
import Image from "gatsby-image";
import SinglePrice from "../../components/SinglePrice";
import SEO from "../../components/seo"
import { graphql } from "gatsby"

const colors = ["a45d64", "b8631a", "938f8e", "bd7c1f", "b4bec0", "bca323", "a69157", "bf0f1b", "aa091d", "a0389a", "3a030c", "8ba5c4", "cce0f4", "1a223c", "56a29d", "0a5980", "06416b", "0f7a5e", "22778d", "1587a4", "105fcb", "343592", "41a3ed", "04268a", "1161cd", "06339f", "0a227c", "299dd5"]

const description = "Profesjonalne usługi tapicerskie. Rozsądne ceny. Cennik tapicer medyczny Konin"
const keywords = ["cenna obicie kozetki", "cennik tapicer medyczny"]

const upholsteryPrice = ({ data }) => (
    <>
        <SEO
            title="Cennik | Tapicer"
            description={description}
            keywords={keywords}
        />
        <div className="prices container">
            <h1 className="title">CENNIK</h1>
            <div className="headerContainer">
                <h2 className="header">Tapicerstwo medyczne</h2>
                <p className="desc">Poniżej przedstawiamy listę osłon medycznych oraz wałków rehabiliatacyjnych. Tolerancja wagi wałków +/- 15%, tolerancja wymiarów +/- 5%. Możliwość uzyskania rabatu przy zamówieniu dużych ilości. W celu precyzyjnego określenia wymiarów Państwa kozetki zaznaczyliśmy odcinki do pomiaru. Pomiar obwodu należy wykonać centymetrem krawieckim.
            <p style={{ fontWeight: "bold", paddingTop: "30px" }}>Wymiary standardowe osłony:</p>
                    <p>A = 55 cm  ·  B = 29 cm  ·  C = 50 cm  ·  D = 29 cm  ·  E = 185 cm  ·  obwód: 138 cm</p>
                </p>
            </div>
            <div className="medicalCouch">
                <Image fluid={data.medic.childImageSharp.fluid} />
            </div>
            <SinglePrice
                header={"Osłona na kozetkę medyczną - 1"}
                desc={"Wymiary standardowe osłony, pokrycie całej kozetki"}
                price={"280 zł"}
            />
            <div className="priceImages">
                <div className="img"><Image fluid={data.medic1a.childImageSharp.fluid} /></div>
                <div className="img"><Image fluid={data.medic1b.childImageSharp.fluid} /></div>

            </div>
            <SinglePrice
                header={"Osłona na kozetkę medyczną - 2"}
                desc={"Wymiary standardowe osłony, przód lub tył kozetki, bez wałka w zestawie"}
                price={"155 zł"}
            />
            <div className="priceImages">
                <div className="img"><Image fluid={data.medic2a.childImageSharp.fluid} /></div>
                <div className="img"><Image fluid={data.medic2b.childImageSharp.fluid} /></div>

            </div>
            <SinglePrice
                header={"Osłona na kozetkę medyczną - 3"}
                desc={"Wymiary standardowe osłony, przód lub tył kozetki, bez wałka w zestawie"}
                price={"185 zł"}
            />
            <div className="priceImages">
                <div className="img"><Image fluid={data.medic3a.childImageSharp.fluid} /></div>
                <div className="img"><Image fluid={data.medic3b.childImageSharp.fluid} /></div>

            </div>
            <Prices
                data={dataPrice.tapicer.medyczny}
            />
            <div className="headerContainer">
                <h2 className="header">Wzornik dostępnych kolorów materiałów powlekanych Skaden</h2>
                <p className="desc">Należy pamietać, że kolory na monitorach mogą odbiegać od rzeczywistych materiałów, co wynika z właściwości kolorystycznych reprezentowanych przez różne rodzaje wyświetlaczy. Wysyłamy próbki materiału.</p>
            </div>
            <div className="colorContainer">
                {colors.map((color, index) => < div
                    style={{ backgroundColor: `#${color}` }}
                    className="color">
                    <p>{index + 1} </p>
                </div>)}

            </div>
        </div>
    </>
)

export const query = graphql`
  {
    medic: file(name: {eq: "price_medic"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    medic1a: file(name: {eq: "price_medic1a"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    medic1b: file(name: {eq: "price_medic1b"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    medic2a: file(name: {eq: "price_medic2a"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    medic2b: file(name: {eq: "price_medic2b"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    medic3a: file(name: {eq: "price_medic3a"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    medic3b: file(name: {eq: "price_medic3b"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }

  }
`
export default upholsteryPrice
